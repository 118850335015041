var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Staff Skills ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("COMPANY", ["STAFF"]) ||
            !_vm.canView("SKILL", ["name"]) ||
            !_vm.canView("SKILL")
              ? [
                  _c("div", { staticClass: "center-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "entity_selector.error.insufficient_permission_to_show_data"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : _vm.noSkills
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("No skills"),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-group-text",
                            attrs: { for: "dateEnd" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("dashboard.widgets.date_range")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("b-form-select", {
                        attrs: {
                          id: "dateEnd",
                          "data-vv-as": _vm.$t("dashboard.widgets.date_range"),
                          "data-vv-name": "dashboard.widgets.date_range",
                          "data-vv-delay": "500",
                          options: _vm.optionTypes,
                          "text-field": "label",
                          "value-field": "key",
                        },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "chart-holder" },
                    [
                      _vm.loaded
                        ? _c("ag-charts-vue", {
                            attrs: { options: _vm.options },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.sourceData.length > _vm.pageSize
                    ? _c("Pager", {
                        attrs: {
                          sourceData: _vm.sourceData,
                          pageSize: _vm.pageSize,
                          small: _vm.width < 450,
                        },
                        on: { paged: _vm.onPageChanged },
                      })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }